import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

const PostSidebar = () => {
  const data = useStaticQuery(graphql`
    query {
      allPosts: allDatoCmsPost(sort: { fields: date, order: DESC }, limit: 20) {
        nodes {
          id
          excerpt
          title
          slug
          content
          coverImage {
            gatsbyImageData(imgixParams: { h: "700", w: "1000" })
          }
          author {
            fullName
          }
          location {
            websiteUrl
          }
          seoMeta {
            description
            title
          }
          meta {
            updatedAt
          }
        }
      }
      blogPage: allDatoCmsMainPage(filter: { slug: { eq: "blog" } }) {
        nodes {
          content
          title
          slug
          pageSeoMeta {
            description
            title
          }
          sideMenuTitle
          headerImage {
            gatsbyImageData(imgixParams: { h: "700", w: "1000" })
          }
        }
      }
    }
  `)
  const allPosts = data.allPosts
  const blogPage = data.blogPage.nodes[0]

  return (
    <div className="md:w-[200px]">
      <div className="font-bold text-3xl">{blogPage.sideMenuTitle}</div>
      <div className="flex flex-col gap-2 pt-3">
        {allPosts.nodes.map((node, index) => (
          <div key={index} className="border-b pb-2">
            <Link
              to={`/blog/${node.slug}`}
              className="hover:underline"
            >
              {node.title}
            </Link>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PostSidebar