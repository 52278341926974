import React from "react"
import { Link } from 'gatsby'
import { getVariables } from "../lib/utils"

const Breadcrumb = ({ items = [] }) => {
    let variables = getVariables()

    return (
        <ol className="my-4 font-bold text-xl" itemScope itemType="http://schema.org/BreadcrumbList">
            <li itemProp="itemListElement" key={`breadcrumb-item-home`} itemScope itemType="http://schema.org/ListItem" className="inline">
                <Link to="/" itemProp="item" className="text-zone-1 hover:text-zone-2 hover:underline">
                    <span itemProp="name">{variables.home}</span>
                </Link>
                <meta itemProp="position" content="1" />
                <span className="mx-2 text-zone-1">/</span>
            </li>
            {items.map( (item, index) => {
                if (item.link !== null) {
                    return (
                        <li itemProp="itemListElement" key={`breadcrumb-item-${index}`} itemScope itemType="http://schema.org/ListItem" className="inline">
                            <Link to={item.link} itemProp="item" className="text-zone-1 hover:text-zone-2 hover:underline mr-2">
                                <span itemProp="name">{item.label}</span>
                            </Link>
                            <meta itemProp="position" content={index+2} />
                            <span className="mx-2 text-zone-1">/</span>
                        </li>
                    )
                } else {
                    return (
                        <li itemProp="itemListElement" key={`breadcrumb-item-${index}`} itemScope itemType="http://schema.org/ListItem" className="inline text-sm">
                            <span itemProp="name" className="text-zone-6">{item.label}</span>
                            <meta itemProp="position" content={index+2} />
                        </li>
                    )
                }
            })
            }
        </ol>
    )
}

export default Breadcrumb
