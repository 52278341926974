import React from "react"
import { graphql } from "gatsby"

import Breadcrumb from "../components/Breadcrumb"
import CoverImage from "../components/CoverImage"
import Layout from "../components/Layout"
import PostSidebar from "../components/PostSidebar"
import Seo from "../components/Seo/Seo"
import { getNewestDate, getVariables } from "../lib/utils"

import "../styles/post.css"

export const query = graphql`
  query ($id: String!) {
    datoCmsPost(id: { eq: $id }) {
      content
      author {
        fullName
      }
      coverImage {
        gatsbyImageData(imgixParams: { h: "700", w: "1000" })
      }
      excerpt
      title
      slug
      meta {
        updatedAt
      }
      id
      location {
        websiteUrl
      }
      seoMeta {
        description
        title
        image {
          url(imgixParams: { fit: "crop", h: "628", w: "1200" })
        }
      }
    }
  }
`
const Post = ({ location, data }) => {
  const variables = getVariables()
  const seoData = data.datoCmsPost.seoMeta
  const dateData = getNewestDate([data.datoCmsPost.meta.updatedAt])

  let breadcrumbData = [
    { link: "/blog", label: "Blog" },
    {
      link: location.pathname,
      label: data.datoCmsPost.title,
    },
  ]

  const {
    author: { fullName },
    content: htmlContent,
    coverImage,
    slug,
  } = data.datoCmsPost

  return (
    <Layout>
      <Seo
        title={seoData.title}
        description={seoData.description}
        image={seoData.image ? seoData.image.url : null}
        pathname={`/${variables.blog}/posts/${slug}`}
      />
      <CoverImage image={coverImage} />
      <div className="bg-zone-1 text-zone-1 p-4 border shadow-md rounded-md">
        <Breadcrumb items={breadcrumbData} />
        <div className="flex justify-center px-4 md:px-0">
          <div className="w-[1400px] py-8 flex flex-col gap-8 md:flex-row">
            <div className="flex-1 flex flex-col gap-4">
              <div className="dynamicContent" dangerouslySetInnerHTML={{ __html: htmlContent }} />

              <div className="pt-4 text-zone-1">{fullName}</div>
              <time
                dateTime={`${dateData.year}-${dateData.month}-${dateData.day}`}
              >
                {variables.last_updated_on} <span>{dateData.month}</span>{" "}
                <span>{dateData.day}</span>, <span>{dateData.year}</span>
              </time>
            </div>
            <PostSidebar />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Post
