import React from "react"

export default function CoverImage({ title, image }) {
  const imageUrl = `url(${image.gatsbyImageData.images.fallback.src})`

  const styleCustom = {
    backgroundImage: imageUrl,
    backgroundPosition: "center center",
    backgroundSize: "cover",
  }

  return (
    <div
      className="bg-gray-500 md:h-[400px] h-[200px] flex items-center justify-center"
      style={styleCustom}
    >
      <div className="text-white font-bold text-4xl">
        {title ? title : null}
      </div>
    </div>
  )
}
